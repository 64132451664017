import React from 'react'
import { Flex } from 'rebass/styled-components';

export default function Section(props) {

    const { sx, padding, children, animation } = props;


    return (
        <Flex as='section' sx={{
            ...sx,
            padding: typeof padding === 'boolean' ? '4rem 1.25rem' :
            typeof padding === 'string' ? padding : [0, 0, '0 1.25rem']
        }}
        {...animation}>
            {children}
        </Flex>
    )
}