export const offer_details_section_props = {
    sx: {
        width: '100%',
        height: 'auto',
        flexFlow: 'column nowrap',
        margin: '0 0 5rem 0'
    }
}

export const offer_details_heading_props = {
    as: 'h1',
    margin: '2rem auto 3.5rem auto',
    padding: '0 1.25rem'
}

export const offer_details_text_props = marginBottom => ({
    as: 'p',
    fontSize: [16, 16, 18],
    margin: `0 0 ${marginBottom} 0`,
})

export const producers_container_props = {
    sx: {
        width: '100%',
        margin: '2rem 0 5rem 0',
        padding: '1.25rem',
        position: 'relative',
        minHeight: 300
    }
}

export const producers_heading_props = conditional => ({
    as: 'h2',
    fontSize: [22, 24],
    background_text: conditional ? 'Producenci' : 'Materiały',
    textAlign: 'left',
    margin: '2rem 0',
    padding: [0, 0, '0 1.25rem']
})

const border_props = (position, width, height, transform, display) => ({
    position, width, height, transform, display
})

export const border_top_props = border_props(
    { top: 0, left: 0 }, ['55%', '40%'], 10, null, null
)

export const border_bottom_props = border_props(
    { bottom: 0, right: 0 }, ['55%', '40%'], 10, { transform: 'rotate(180deg)' }, null
)

export const border_right_props = border_props(
    { bottom: 0, right: 0 }, 10, '45%',
    { transform: 'rotate(-360deg)' }, { display: ['none', 'none', 'inherit'] }
)

export const border_left_props = border_props(
    { top: 0, left: 0 }, 10, '45%',
    { transform: 'rotate(180deg)' }, { display: ['none', 'none', 'inherit'] }
)